import React, { Component } from 'react';
import { Link } from "gatsby";
import '../css/App.css';

class App extends Component  {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      ukData: [],
      currentCount: 0,
      dataCount: 0,
      velocity: 0,
      thenDate: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        23,59,59),
      rollingAverageDays: 5,
      currentPercent: 0,
    };
    
  }
  componentDidMount() {
    const apiUrl = 'https://api.coronavirus.data.gov.uk/v2/data?areaType=overview&metric=cumPeopleVaccinatedFirstDoseByPublishDate&metric=cumPeopleVaccinatedSecondDoseByPublishDate&metric=newPeopleVaccinatedFirstDoseByPublishDate&format=json';
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        this.setState({dataCount: data['body'][0].cumPeopleVaccinatedFirstDoseByPublishDate});
        // Try to work out the velocity.
        let shots = 0;
        this.setState({rollingAverageDays: Math.min(this.state.rollingAverageDays, data['body'].length)})
        for (var i = 0; i < this.state.rollingAverageDays; i++) {
          shots += data['body'][i].newPeopleVaccinatedFirstDoseByPublishDate;
        }
        this.setState({velocity: shots / ( this.state.rollingAverageDays * 86400000)});

        // Parse the last date of data in the feed.
        const dateparts = data['body'][0].date.split('-');
        this.setState({thenDate: new Date(
          parseInt(dateparts[0], 10),
          parseInt(dateparts[1], 10) - 1,
          parseInt(dateparts[2], 10),
          // Data comes out at around 4pm.
        23,59,59
        )});
        this.updateCounter();
        let intervalId = setInterval(() => this.updateCounter(), 250);
        this.setState({ intervalId: intervalId });
      });
  }
  componentWillUnmount(){
    clearInterval(this.state.intervalId);
  }
  updateCounter() {
    // We need to compute the amount of time elaspsed since midnight.
    const now = new Date();
    const diff = now.getTime() - this.state.thenDate.getTime();
    this.setState({currentCount: Math.max(this.state.dataCount, Math.round(this.state.dataCount + diff * this.state.velocity))});
    this.setState({currentPercent: this.state.currentCount / 67886011})
  }
  render() {
    return (
      <div>
        <div className="text-center">
        <h1 className="fa-center text-5xl text-center mb-8 mt-4 border-b-4 border-indigo-700 inline-block">Covid Vaccination UK</h1>
      </div>
      <div className="text-center">
          <p className="font-mono text-4xl text-indigo-700">{this.state.currentCount.toLocaleString()}</p>
          <p className="text-lg mb-4">
           1st doses administered*
          </p>
          <p className="font-mono text-4xl text-indigo-700">{this.state.currentPercent.toLocaleString(undefined,{style: 'percent', minimumFractionDigits: 2})}</p>
          <p className="text-lg mb-4">of 2020 UK population*</p>
          <p className="">
            * Estimate based on last {this.state.rollingAverageDays} days of available vaccination data.
          </p>
          </div>
          <div className="text-center">
        <footer className="App-footer mt-8">
           <Link to="/" className="rounded-md py-3 px-6 bg-white hover:bg-indigo-50 border border-indigo-500 hover:border-indigo-800 shadow-md">Home</Link>
          </footer>
          </div>
      </div>
    );
  }
}

export default App;
